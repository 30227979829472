import Vue from 'vue'
import App from './App.vue'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  components: {
    ChartJsPluginDataLabels,
  },
}).$mount('#app')
