<template>
  <div class="small">
    <line-chart class="container" :chart-data="datacollection" :options="options"></line-chart>
  </div>
</template>

<script>
import LineChart from './LineChart.js'

export default {
  components: {
    LineChart
  },
  props: {
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      datacollection: {},
      cw: 0,
      tw: 0,
      options: false,
    }
  },
  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(urlSearchParams.entries())

    if (query.cw) {
      this.cw = parseInt(query.cw)
    }
    if (query.tw) {
      this.tw = parseInt(query.tw)
    }

    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          color: '#12ad71',
          font: {
            size: 16,
            weight: 700
          }
        }
      },
      scales: {
        yAxes: [{
          display: false,
          ticks: {
            suggestedMin: this.tw - 1,
            suggestedMax: this.cw + 1
          },

        }],
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      elements: {
        point: {
          backgroundColor: '#ffdd49'
        }
      }
    }
    this.fillData()
  },
  computed: {
    getDelta() {
      return parseInt(this.cw) - parseInt(this.tw)
    },
    getPlanIterations() {
      if (this.getDelta >= 1 && this.getDelta <= 4) {
        return 1
      }

      if (this.getDelta >= 5 && this.getDelta <= 7) {
        return 2
      }

      if (this.getDelta >= 8 && this.getDelta <= 11) {
        return 3
      }

      if (this.getDelta >= 12 && this.getDelta <= 15) {
        return 4
      }

      if (this.getDelta >= 16 && this.getDelta <= 26) {
        return 6
      }

      return 8
    },
    getLabelFromIteration() {
      const arr = ['', 'Mois M']
      const toMap = [...Array(this.getPlanIterations).keys()]
      toMap.map((key) => {
        arr.push('Mois ' + (key + 1))
      })
      arr.push('')
      return arr
    },
    getDataFromIteration() {
      if (this.getPlanIterations === 1) {
        return [
          (this.cw + 1), (this.cw -1),
          this.tw,
          (this.tw - 1)
        ]

      }

      if (this.getPlanIterations === 2) {
        return [
          (this.cw + 1),
          Math.round(this.cw - (this.cw - this.tw) * 0.1),
          Math.round(this.cw - (this.cw - this.tw) * 0.5),
          this.tw,
          (this.tw - 1)
        ]

      }

      if (this.getPlanIterations === 3) {
        return [
          (this.cw + 1),
          this.cw - Math.round((this.cw - this.tw) * 0.1),
          Math.round(this.cw - (this.cw - this.tw) * 0.4),
          Math.round(this.cw - (this.cw - this.tw) * 0.75),
          this.tw,
          (this.tw - 1)
        ]

      }

      if (this.getPlanIterations === 4) {
        return [
          (this.cw + 1),
          this.cw - Math.round((this.cw - this.tw) * 0.1),
          Math.round(this.cw - (this.cw - this.tw) * 0.35),
          Math.round(this.cw - (this.cw - this.tw) * 0.65),
          Math.round(this.cw - (this.cw - this.tw) * 0.85),
          this.tw,
          (this.tw - 1)
        ]

      }

      if (this.getPlanIterations === 6) {
        return [
          (this.cw + 1),
          Math.round(this.cw - (this.cw - this.tw) * 0.1),
          Math.round(this.cw - (this.cw - this.tw) * 0.2),
          Math.round(this.cw - (this.cw - this.tw) * 0.4),
          Math.round(this.cw - (this.cw - this.tw) * 0.6),
          Math.round(this.cw - (this.cw - this.tw) * 0.8),
          Math.round(this.cw - (this.cw - this.tw) * 0.9),
          this.tw,
          (this.tw - 1)
        ]

      }

      if (this.getPlanIterations === 8) {
        return [
          this.cw + 3,
          Math.round(this.cw - (this.cw - this.tw) * 0.05),
          Math.round(this.cw - (this.cw - this.tw) * 0.22),
          Math.round(this.cw - (this.cw - this.tw) * 0.4),
          Math.round(this.cw - (this.cw - this.tw) * 0.55),
          Math.round(this.cw - (this.cw - this.tw) * 0.7),
          Math.round(this.cw - (this.cw - this.tw) * 0.8),
          Math.round(this.cw - (this.cw - this.tw) * 0.9),
          Math.round(this.cw - (this.cw - this.tw) * 0.95),
          this.tw,
          (this.tw - 2)
        ]

      }

      return []

    }
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: this.getLabelFromIteration,
        datasets: [
          {
            label: '',
            backgroundColor: [
              '#074957',
            ],
            borderWidth: [0, 0, 0, 0, 0],
            data: this.getDataFromIteration
          },

        ]
      }
    },
  }
}
</script>

<style>
.container {
  position: relative;
  height: 335px;
}

.small {
  position: relative;
  width: 120%;
  transform: translateX(-9%);
}

</style>
