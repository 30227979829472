<template>
  <div id="app">
    <random-chart />
  </div>
</template>

<script>
import RandomChart from './components/RandomChart.vue'

export default {
  name: 'App',
  components: {
    RandomChart
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  margin: 0;
}
</style>
